<template>
  <div class="emailInfo-container page-block" id="kapcsolat">
    <h2 class="text-center text-uppercase"> {{tr('contact-title')}}</h2>
    <!--<p class="text-center mb-5" style="color: #A72585!important;" v-html="tr('contact-text')"></p>-->
    <div class="container-fluid justify-content-center row m-0">
      <div class="m-a col-12 col-lg-4">        
        <validationObserver
            v-if="!formStatus"
            v-slot="{ handleSubmit }"
            tag="div"
            ref="contactObserver"
        >
          <div class="">
            <form id="contactForm"
                class="form form--codefilling"
                @submit.prevent="handleSubmit(formSubmit)"                
            >
              <div class="forename-row w-100 input--info">
                <ValidationProvider
                    rules="required"
                    v-slot="{ failedRules, errors }"
                    vid="name"
                >                 
                  <label for="name" class="form-label email-row">{{tr('contact-input-name')}}<span class="ml-1 error">*</span></label>
                  <input v-model="form.name" :placeholder="tr('contact-name-placeholder')" type="text"
                         class="form-control email-placeholder"
                         id="name">
                  <span class="error" v-if="getErrorMessage('name', Object.entries(failedRules)[0])">{{
                      getErrorMessage("name", Object.entries(failedRules)[0])
                    }}</span>
                  <div class="error" v-else>{{errors[0]}}</div>
                </ValidationProvider>
              </div>
              <div class="email-row w-100 input--info">

                <ValidationProvider
                    rules="required|email"
                    v-slot="{ failedRules, errors }"
                    vid="email"
                >                  
                  <label for="recipient-name" class="form-label mt-4 email-row">{{tr('contact-input-email')}}<span class="ml-1 error">*</span></label>
                  <input v-model="form.email" :placeholder="tr('contact-email-placeholder')" type="text"
                         class="form-control email-placeholder"
                         id="recipient-name">
                  <span class="error" v-if=" getErrorMessage('email', Object.entries(failedRules)[0])">{{
                      getErrorMessage("email", Object.entries(failedRules)[0])
                    }}</span>
                  <div class="error" v-else>{{errors[0]}}</div>
                </ValidationProvider>
              </div>          
              <div class="email-row w-100 input--info">
                <ValidationProvider
                      rules="required"
                      v-slot="{ failedRules, errors }"
                      vid="topic"
                  >        
                  <label for="topic" class="form-label mt-4 email-row">{{tr('contact-select-title')}}<span class="ml-1 error">*</span></label>           
                      <b-form-select v-model="form.topic" name='topic'>  
                        <b-form-select-option :value="null">{{tr('contact-form-choose-topic')}}</b-form-select-option>                      
                          <b-form-select-option :value="tr('contact-select-promocio')">{{tr('contact-select-promocio')}}</b-form-select-option>
                          <b-form-select-option :value="tr('contact-select-product')">{{tr('contact-select-product')}}</b-form-select-option>
                        </b-form-select>
                    <span class="error" v-if=" getErrorMessage('topic', Object.entries(failedRules)[0])">{{
                        getErrorMessage("topic", Object.entries(failedRules)[0])
                      }}</span>
                    <div class="error" v-else>{{errors[0]}}</div>
                </ValidationProvider>
              </div>
              <ValidationProvider
                  rules="required"
                  v-slot="{ failedRules, errors }"
                  class="message-row"
                  vid="message"
              >
                <label for="message-text" class="form-label mt-4 ">{{tr('contact-input-msg')}}<span class="ml-1 error">*</span></label>
                <textarea type="text" v-model="form.message" :placeholder="tr('contact-msg-placeholder')"
                          class="form-control textarea py-3"
                          id="message-text">
                </textarea>
                <span class="error" v-if="getErrorMessage('message', Object.entries(failedRules)[0])">{{
                    getErrorMessage("message", Object.entries(failedRules)[0])
                  }}</span>
                <div class="error" v-else>{{errors[0]}}</div>
              </ValidationProvider> 
                <div class="custom-control custom-checkbox checkbox-margin condition-row pt-3">
                <ValidationProvider
                      rules="required|acceptConditions"
                      v-slot="{ failedRules, errors }"
                      tag="div"
                      vid="condition"
                  >
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.condition"
                        type="checkbox"
                        class="custom-control-input"
                        id="conditions-contact"
                        name="condition"
                    />
                    <label
                        class="custom-control-label mb-0 condition-label"
                        for="conditions-contact"
                    >
                      <div>
                        <span v-html="tr('contact-form-chbx-1')"></span>
                        <span style="text-decoration:none; color: red;"><strong>*</strong></span>
                      </div>

                    </label>
                    <div class="mt-4"></div>
                    <span class="error" v-if="getErrorMessage('condition', Object.entries(failedRules)[0])">{{
                        getErrorMessage("condition", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>


              <div v-if="!formStatus" class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn btn-third " :disabled="formSubmitted">{{tr('contact-btn')}}</button>
              </div>
            </form>
          </div>
        </validationObserver>

        <!-- FORM RESULT -->
        <!-- <p v-if="sendingError" class="error error--send alert alert-danger mt-3">{{tr('contact-form-result-unsuccess')}}</p> -->
        <div v-if="formStatus === true" class="contact__result-block">
          <img src="@/assets/img/icons/ic-success-contact.png" />
          <h2  class="text-center ">
            {{tr('contact-form-result-success')}}
          </h2>          
          <div class="d-flex justify-content-center" >
            <button type="button" class="btn btn-third m-auto" @click="defaultFormState(); formStatus = false">
              {{tr('contact-form-back')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI.js";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import {extend} from "vee-validate";
import $ from "jquery";


extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  // message: "A Játékszabályzatot kötelező elfogadnia. ",
});

export default {
  name: "emailInfo",
  mixins: [apiHandlerMI],
  data() {
    return {
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_BASE_URL,
      },
      sendingError: false,
      formStatus: false,
      form: {
        condition: 0,
        topic: null,
        email: "",
        name: "",
        message: null,        
        // condition: '0',
      },
      formSubmitted: false,
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  }, 
  methods: {
    backToMain() {
      window.location.href = "/";
    },
    removeError(fieldName) {
      $(`#contactModal .${fieldName}-row .error-2`).remove();
    },
   
    defaultFormState() {
      let _this = this

      _this.form.name = '';
      _this.form.email = "";
      _this.form.condition = 0;
      _this.form.topic = null;
      _this.form.message = null;      
      _this.formSubmitted = false
    },    
    formSubmit() {

      var _this = this;      

      if (!_this.formSubmitted) {
        _this.formSubmitted = true

        _this.$axios
            .post(process.env.VUE_APP_API_URL + 'message', {
              name: this.form.name,
              email: this.form.email,
              condition: this.form.condition,
              message: this.form.message,
              topic: this.form.topic
            })
            .then((response) => {
              // console.log(response.data)
              
              if (response.data.status === true) {
                //show the win or not win block instead of the codefilling form
                _this.formStatus = true;
                _this.defaultFormState()
              
              } else {
                $(".form-subtitle").append(
                    '<div class="error-2">Hiba történt, kérjük, próbáld újra.</div>'
                );
                
              }

              _this.formSubmitted = false

            })
            .catch(function (error) {
              console.log(error);
              //_this.sendingError = true
              _this.formSubmitted = false

              _this.goToError(error)  

            });

      } else {
        console.log('double form submit')
      }

    },
     goToError(err){            
        let _this = this
        //scroll to the first error
        console.log('helo')
        this.setErrorMsgs(err, 'contactObserver').then(()=>{                               
          let firstError = document.querySelector('#contactForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }
          //document.getElementById('promotionCodeForm').scrollIntoView(true);
        })                                       
    },
  }  
};
</script>
